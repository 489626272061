// types
import { DefaultConfigProps } from 'types/config';

// ==============================|| GENERAL APP CONFIG  ||============================== //
export const ENVIRONMENT: "dev" | "prod" = "dev";
export const FT_ORG_ID = "6Cj5eVirJIvVhxQZQTfy";
export const APP_URL = "https://dev.forsler.com";
export const ERROR_LOG_ENABLED = false;
export const DEFAULT_FILES_BUCKET = "gs://todaysforest-dev_app_files";

// ==============================|| THIRD PARTY CONFIG  ||============================== //
// --- Firebase config --- //
export const FIREBASE_API = require("./firebase-dev.json");
export const CLOUD_FUNCTIONS_URL = "https://europe-west3-todaysforest-dev.cloudfunctions.net/";
export const INTERNAL_API_BASE_URL = "https://foresting-tomorrow-internal-d5yy7f55nq-ey.a.run.app";

////////////////// PROD TEST //////////////////////
// export const FIREBASE_API = require("./firebase-prod.json");
// export const CLOUD_FUNCTIONS_URL = "https://europe-west3-forsler-prod.cloudfunctions.net/";
// export const INTERNAL_API_BASE_URL = "https://foresting-tomorrow-internal-4yr7fia4nq-ey.a.run.app";
////////////////// PROD TEST END //////////////////

// --- Product fruits config --- //
export const PF_WORKSPACE_CODE = "P88FHpgM1g0XBdee";
export const CRISP_KEY = "485edb08-b17e-4fa6-82b3-8287d623a09f";

////////////////// PROD TEST //////////////////////
// export const PF_WORKSPACE_CODE = "EcwDOIyEtb9ljkdl";
// export const CRISP_KEY = "485edb08-b17e-4fa6-82b3-8287d623a09f";
////////////////// PROD TEST END //////////////////

// --- Mixpanel config --- //
export const MP_TOKEN = "5a6f489b30b3213e27c7afbaf1eb8301";
export const MP_DEBUG = false;

// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
   defaultPath: '/home-page',
   fontFamily: `'Inter', sans-serif`,
   i18n: 'en',
   miniDrawer: false,
   container: false,
   mode: 'light',
   presetColor: 'theme3',
   themeDirection: 'ltr'
};


export default config;
